/* SASS variables */

/* Path */
$theme-path: '/_themes/design2015';
$assets-path: '/assets';

/* Font stacks */
  $default-fontstack: arial, helvetica, sans-serif;
  $tagline-fontstack: arial, helvetica, sans-serif;
  $header-fontstack: arial, helvetica, sans-serif;
  $nav-fontstack: arial, helvetica, sans-serif;

/*globerthin_free*/

/* Font sizes */
  $default-fontsize: 14px;
  $default-fontweight: 300;
  $default-lineheight: 22px;
  $nav-fontsize: 20px;
  $nav-fontsize-480: 14px;
  $nav-fontsize-768: 16px;
  $nav-fontsize-980: 18px;
  $footer-fontsize: 12px;
  $tagline-fontsize: 30px;
  $tagline-fontsize-768: 27px;
  $tagline-fontsize-480: 17px;
  $tagline-lineheight: 64px;
  $tagline-lineheight-768: 54px;
  $tagline-lineheight-480: 44px;
  $tagline-fontweight: 100;
  $block-summary-fontsize: 20px;
  $block-summary-line-height: 30px;

/* Site-specific colour palette */
$color-primary: #001e3a;
$color-secondary: #002b54;
$color-tertiary: #82d7f7;
$default-fontcolor: #5e5e5e;
$header-fontcolor: #333333;
  $nav-color: #fff;
$color-black: #000000;
$color-grey: #9f9f9f;
$color-darkgrey: #515151;
$color-blue: #5c7589;
$color-gold: #a99970;
$color-yellow: #ffcc00;
$color-white: #ffffff;
$color-navbar-bg: $color-primary;
$color-header-bg: $color-secondary;
  $color-hover: #bfa72a;
  $color-active: #bfa72a;
$color-link: #1761ac;
$color-bodybg: #fff;

/* Additional colour palette */
  $color-transblack: rgba(0,0,0,0.6);
  $color-footer-bg: #456966;
  $color-footer-text: #fff;
  $color-tagline-bg: #CD712A;
  $color-button-primary: #ce3734;
  $color-breadcrumbs-bg: #F2F2F2;
$color-dropdown: $color-primary;

/* Width and height settings */
  $headernav-height: 40px;
$headerlogo-height: 180px;
  $navbar-height: 40px;
  $subnav-height: 35px;
  $subnav-extra-height: 55px;
$header-height: 180px;
  $header-img-height: 205px;
  $page-title-height: 105px;
  $home-title-height: 150px;
  $home-feature-height: 196px;
$home-slider-height: 410px;
$sub-slider-height: 250px;
  $footer-height: 50px;
  $nav-padding: 10px;
  $breadcrumbs-height: 40px;

/* Breakpoints */
$mini: 320px;
$small: 480px;
$medium: 768px;
$large: 960px;
$xlarge: 1220px;

/* SASS Mixins */
/* Vertical align center everything */
@mixin vertical-align {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

@mixin preserve-3d {
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

  /* Text shadow */
  @mixin textshadow($offset-y,$offset-x,$blur,$color,$opacity){
    text-shadow: $offset-y $offset-x $blur rgba($color, $opacity);
  }

  /* Opacity and colour */
  @mixin alpha-attribute($attribute, $color, $background) {
    $percent: alpha($color) * 100%;
    $opaque: opacify($color, 1);
    $solid-color: mix($opaque, $background, $percent);
    #{$attribute}: $solid-color;
    #{$attribute}: $color;
  }

@mixin box-shadow($offset-y,$offset-x,$blur,$color,$opacity) {
  -webkit-box-shadow: $offset-y $offset-x $blur rgba($color, $opacity);
  -moz-box-shadow: $offset-y $offset-x $blur rgba($color, $opacity);
  box-shadow: $offset-y $offset-x $blur rgba($color, $opacity);
  background-clip: padding-box;
}

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  border-radius: $radius;
  background-clip: padding-box;
}

// Single side border-radius
@mixin border-topleft-radius($radius) {
   -webkit-border-top-left-radius: $radius;
   -moz-border-top-left-radius: $radius;
   border-top-left-radius: $radius;
   background-clip: padding-box;
}
@mixin border-topright-radius($radius) {
   -webkit-border-top-right-radius: $radius;
   -moz-border-top-right-radius: $radius;
   border-top-right-radius: $radius;
   background-clip: padding-box;
}
@mixin border-bottomright-radius($radius) {
   -webkit-border-bottom-right-radius: $radius;
   -moz-border-bottom-right-radius: $radius;
   border-bottom-right-radius: $radius;
   background-clip: padding-box;
}
@mixin border-bottomleft-radius($radius) {
   -webkit-border-bottom-left-radius: $radius;
   -moz-border-bottom-left-radius: $radius;
   border-bottom-left-radius: $radius;
   background-clip: padding-box;
}
