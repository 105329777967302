/*
 *
 * Design by Adnet Communications Inc.
 * Implementation by Adnet Communications Inc.
 * By Rudy Affandi
 * (c) 2015
 *
 */

/* Set up variables */
@import "_variables.scss";

/* Import UIKit core */
@import "../bower_components/uikit/scss/uikit-mixins.scss";
@import "../bower_components/uikit/scss/uikit.scss";

/* Import UIKit components */
@import "../bower_components/uikit/scss/components/notify.scss";
@import "../bower_components/uikit/scss/components/search.scss";
@import "../bower_components/uikit/scss/components/tooltip.scss";
@import "../bower_components/uikit/scss/components/accordion.scss";
@import "../bower_components/uikit/scss/components/placeholder.scss";
@import "../bower_components/uikit/scss/components/progress.scss";

/* Import customized sass */
@import "bourbon/bourbon";
@import "_plugins.scss";
@import "_cms.scss";
@import "_custom.scss";
@import "_print.scss";
