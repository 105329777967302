/*
 * Design by Adnet Communications Inc.
 * Implementation by Adnet Communications Inc.
 * (c) 2015
 */

/* links */
a {color: $color-link}
.uk-active a,
a:hover {text-decoration: none; color: $color-secondary;}

/* Sticky footer */
html {}

/* Typography */
html, body {line-height: $default-lineheight; font-size: $default-fontsize; font-weight: $default-fontweight; color: $default-fontcolor}
html, body {font-family: $default-fontstack}

small {font-size:11px;}

/* Colour stuff */
.color-primary {color: $color-primary !important;}
.color-secondary {color: $color-secondary !important;}
.color-tertiary {color: $color-tertiary !important;}
.bg-primary {background-color: $color-primary !important;}
.bg-secondary {background-color: $color-secondary !important;}
.bg-tertiary {background-color: $color-tertiary !important;}
.bg-white {background-color: $color-white !important;}

/* Headers */
h1, h2, h3, h4 {font-family: $default-fontstack;}
h1, h2 {font-size: 24px;}

/* Header nav */

/* Header logo */

/* Sticky nav */
.navbar.sticky-nav {position: fixed; left: 0; top: 0; display: block; z-index: 2;}
.header-bg.sticky-nav {margin-top: $navbar-height;}

/* Off-canvas nav */
.mobile-nav {position: absolute; width: 100px; height: 30px; left: 10px; top: 7px;}
.mobile-nav a {color: $color-white;}
.uk-offcanvas-bar {background: $color-navbar-bg;}
.uk-nav-offcanvas>.uk-open>a, html:not(.uk-touch) .uk-nav-offcanvas>li>a:focus, html:not(.uk-touch) .uk-nav-offcanvas>li>a:hover {background: darken($color-navbar-bg,15%);}
.uk-nav-offcanvas ul a span {font-family: $nav-fontstack}
.uk-nav-header .uk-panel {margin: 0}
.burgers {position: absolute; left: 0; top: 0; text-align: left; font-size: 30px}
.uk-navbar-toggle {float: none; font-size: 22px}
.uk-nav-offcanvas>li {font-size: 16px}
.uk-offcanvas {}
.uk-offcanvas li.uk-nav-divider {border-top: 1px solid #222; border-bottom: 1px solid #555; border-width: 1px 0}
.uk-offcanvas .legal {color: #777; font-size: 12px}

/* Social media */
.icons {z-index: 2;}
.social {display: block; float: right; list-style: none; margin: 8px 0 0; padding: 0; vertical-align: middle;}
.social li {display: inline-block; margin: 0; padding: 0; width: 24px; height: 24px; text-align: center; line-height: 24px; @include border-radius(4px);}
.social li a {display: block; color: $color-white; width: 24px; height: 24px;}
.social li i {margin-top: 3px;}
.facebook {background-color: #3B5998; color: $color-white;}
.linkedin {background-color: #007bb6; color: $color-white;}
.twitter {background-color: #4099ff; color: $color-white;}
.flickr {background-color: #007bb6; color: $color-white;}
.instagram {background-color: #007bb6; color: $color-white;}
.youtube {background-color: #cd201f; color: $color-white;}
.rss {background-color: #f60; color: $color-white;}

/* Stock Quote */
.stockprice {color:$color-tertiary;line-height: 48px}

/* Tagline */
.top-tagline {color:$color-tertiary;font-size: 28px; line-height: 30px; text-transform: uppercase;}
.top-tagline h2 {color:$color-white;font-size: 48px; line-height: 50px; text-transform: uppercase;padding-left: 100px}

/* Backgrounds */
.header-bg {background: $color-primary url(/_themes/design2015/img/header-bg.jpg) no-repeat top center; background-size: cover}

/* Logo */
.logo {display: block;}
.logo img {height: 100px; padding: 20px 0}

/* Main nav */
.navbar {font-family: $nav-fontstack; height: $navbar-height; position: relative; width: 100%;}
.navbar-bg {background-color: $color-navbar-bg;}

/* Navbar Search box */
.navbar .uk-search-field {border: 1px solid #2a2a2a; background-color: #5d5d5d; @include border-radius(4px); width: 130px; height: 35px; margin-top: 5px; padding: 0 30px 0 6px; font-size: 13px;}
.navbar .uk-search-field:focus {background-color: rgba($color-white,0.9); width: 200px;}
.navbar .uk-search:before {left: auto; right: 0; top: 6px;}

/* Main navigation */

/* Default settings */
.main-nav, .main-nav * {list-style: none; margin: 0; padding: 0;}
.main-nav li {position: relative;}
.main-nav ul {position: absolute; display: none; top: 100%; left: 0; z-index: 99;}
.main-nav > li {float: left;}
.main-nav li:hover > ul, .main-nav li.hover > ul {display: block;}
.main-nav a {display: block; position: relative;}
.main-nav > ul {top: 0; left: 100%; transition: all .2s ease-in-out;}

/* Customized based on theme */
.main-nav > li {margin: 0 15px;}
.main-nav > li.first {margin-left: 0;}
.main-nav > li.last {margin-right: 0;}
.main-nav > li > a {display: block; color: $color-white; margin: 0; padding: 0; line-height: $navbar-height; font-size: 16px; font-weight: bold; text-transform: uppercase}
.main-nav > li > a > i {margin-top: 13px;}
.main-nav > li > a > i.uk-icon-home {margin-top: 12px;}
.main-nav > li:hover, .main-nav > li > a:hover, .main-nav > li:hover > a, .main-nav > li > a:focus {color: $color-tertiary;}
.main-nav > li.uk-active > a {color: $color-tertiary; text-decoration: none;}

/* Children */
.main-nav li li > a {color: $color-white; padding: 0 20px;font-weight: normal; text-transform: none}
.main-nav li li > a:hover, .main-nav li li:hover > a, .main-nav li li.uk-active > a {color: $color-tertiary;}

/* Secondary level */
.main-nav ul li, .main-nav ul li a {color: $color-white; font-size: 14px;}
.main-nav ul > li.uk-parent > a {display: block}
.main-nav li:hover ul, .main-nav > li a:hover ul, .main-nav > li:hover a ul {display: block;}
.main-nav ul {font-family: $default-fontstack; min-width: 320px; @include border-bottomleft-radius(4px); @include border-bottomright-radius(4px); background: rgba($color-dropdown, 0.9); text-align: left; padding: 0; left: -20px;}

/* Tertiary level */
.main-nav ul ul {display: block; position: relative; left: 0; background: none; padding: 0 20px;}

/* Side Nav */
#page-aside #subnav ul li {padding-top: 10px}

/* Sliders */
#slider {width: 100%; height: 120px; position: relative; z-index: 1; top: 0; overflow: hidden;}
.home .slide, 
.home #slider {height: 120px}
.caption-bg {background-color: rgba($color-black, 0.7); color: $color-white; margin: 50px 20px; padding: 30px 40px}
.caption-bg h1.header {color: $color-white; font-family: $tagline-fontstack; text-transform: uppercase}
.caption-bg h1.header .word5 {color: $color-hover;}
.uk-dotnav>*>* {width: 15px; height: 15px; background: rgba($color-secondary, 0.4);}
.uk-dotnav>.uk-active>* {transform: none; background: $color-secondary;}
#slider .uk-dotnav.uk-position-bottom {bottom: 60px;}
#slider .uk-dotnav>*>* {background: $color-secondary; box-shadow: 0 0 10px rgba($color-black, 0.3)}
#slider .uk-dotnav>.uk-active>* {background: $color-white;}
#slider-nav span {display: inline-block; width: 12px; height: 12px; border-radius: 12px; background: $color-white; margin: 0 3px; text-indent: -9999em; cursor: pointer}
#slider-nav span:hover {background: $color-primary}
#slider-nav span.cycle-pager-active {background: #003661; border: 1px solid #0e94db}

.uk-slidenav {background-color: rgba($color-black, 0.5); width: 45px; height: 140px; line-height: 140px;}
.uk-slidenav-position .uk-slidenav {top: 40%; display: block;}
.uk-slidenav-position .uk-slidenav-previous {left: 0;}
.uk-slidenav-position .uk-slidenav-next {right: 0;}

#slider .uk-slidenav {position: absolute; z-index: 200; margin: 0 0 100px 0;}
#slider .uk-slidenav.uk-slidenav-previous {left: 0; top: 35%;}
#slider .uk-slidenav.uk-slidenav-next {right: 0; top: 35%;}
#sliderpager.uk-dotnav {margin-top: -100px; z-index: 200; position: relative;}
#sliderpager.uk-dotnav>.cycle-pager-active>* {background: $color-white;}

#slider .slider-text {margin-top: 50px; padding: 10px; color: #ffffff; }
#slider .slider-text h2 {color: #ffffff;text-shadow: 3px 3px 2px rgba($color-primary, 0.74);}
#slider .slider-text h3 {color: #ffffff;text-shadow: 3px 3px 2px rgba($color-primary, 0.74);}
#slider .slider-text a {padding: 5px;color: #ffffff; background: rgba($color-primary,0.8)}
#slider .slider-text a i {padding-left: 10px;color: #ffffff; line-height: 24px;}

/* Tagline */
.tagline {text-align: center; width: 60%; margin-top: 95px; position: absolute; left: 19.5%;}
.tagline h1 {display: inline-block; padding: 10px 30px; font-family: $tagline-fontstack; font-weight: 400; color: $color-secondary; font-size: 36px; line-height: 46px; text-shadow: 0 0 20px rgba(0,0,0,0.6); text-align: center; background-color: rgba($color-black, 0.3);}
.tagline h2 {display: inline-block; margin: 5px 0 15px; padding: 10px 30px; font-family: $tagline-fontstack; font-weight: 400; color: $color-white; font-size: 24px; line-height: 30px; text-shadow: 0 0 20px rgba(0,0,0,0.6); text-align: center; background-color: rgba($color-black, 0.3);}
.uk-button.button-rounded {@include border-radius(8px); margin-top: 20px;}

/* News Item list - Home Page */
.news-item {padding-bottom: 30px;}

/* Feature boxes */
.feature-links .link-item {max-width: 360px}
.feature-links .link-text {padding-left: 10px; color: $color-white; font-size: 14px; line-height: 18px; background: $color-secondary url(/_themes/design2015/img/feature-bg.png) no-repeat right}
.feature-links .link-img  {height: 83px;background: $color-secondary; overflow: hidden}
.feature-links .link-img img {width: 100%; min-height: 73px; transition: 2s}

.feature-links .link-item:hover .link-img img {opacity: 0;}

/* Mobile subnav */
#subnav-selector {margin-top: 2px !important}

/* Page body */
/*#page-body {position: relative; z-index: 2; margin-top: ($navbar-height + $sub-slider-height); background-color: $color-white}
.home #page-body {position: relative; z-index: 2; margin-top: ($navbar-height + $home-slider-height + $navbar-height); background-color: $color-white}
#page-body {position: relative; z-index: 2; margin-top: ($navbar-height + $sub-slider-height); background-color: $color-white}
.home #page-body {position: relative; z-index: 2; margin-top: ($navbar-height + $home-slider-height); background-color: $color-white}

*/
#page-body {position: relative; background-color: $color-white}
.home #page-body {position: relative; background-color: $color-white}
#page-content span[style] {line-height: auto}

/* List */
.item-list {line-height: normal;}
.item-list a {text-decoration: underline;}

/* Page content */

/* Footer */
#page-footer {line-height: 16px; font-size: 14px}
.footer-bg {border-top: 1px solid #e0e0e0}

/* Footer nav */
.footer-nav {}
.footer-nav li {display: inline-block; margin: 0 15px;}
.footer-nav li a {display: block; margin: 0; padding: 5px;}
.footer-nav li:hover a, .footer-nav li a:hover, .footer-nav li.uk-active a {}

/* Parsley overrides */
input.parsley-success,select.parsley-success,textarea.parsley-success {color: #468847;background-color: #DFF0D8;border: 1px solid #D6E9C6}
input.parsley-error,select.parsley-error,textarea.parsley-error{color:#B94A48;background-color:#F2DEDE;border: 1px solid #EED3D7}
.parsley-errors-list {margin: 2px 0 3px;padding: 0; color: #c00;list-style-type: none;font-size: 0.9em;line-height: 0.9em;opacity: 0;transition: all .3s ease-in;-o-transition: all .3s ease-in;-moz-transition: all .3s ease-in;-webkit-transition: all .3s ease-in}
.parsley-errors-list.filled {opacity:1}
.footer-features .parsley-errors-list {position: absolute; left:0;bottom:-25px}
#contact .parsley-errors-list {margin-top: 5px}

/* Pop up form */
.popup {display: none}
#subscribe-form.popup {position: absolute; top: -255px; left: 0; width: 312px; @include box-shadow(2px,2px,5px,#666,0.4)}
.home-sub-button > .uk-button-primary {font-size: 14px;background: $color-secondary url(/_themes/design2015/img/feature-bg.png) no-repeat right !important; transition: background 2s}
.home-sub-button > .uk-button-primary:hover {background: $color-tertiary url(/_themes/design2015/img/feature-bg.png) no-repeat right !important}

/* Map */
.map {width: 100%; height: 350px}
.map img {max-width: none}
svg { max-height: 100%}

.project-map {width:100%; max-width:400px}
a.project-location {display:block;width: 56px; height: 30px;position: absolute}

/* uikit overrides */
em {color: inherit}

/* Home Page */

/* Tables */

/* Pop up form */

/* Misc */
.iframe-container {position: relative; padding-bottom: 56.25%; padding-top: 35px; height: 0; overflow: hidden;}
.iframe-container iframe {position: absolute; top: 0; left: 0; width: 100%; height: 100%;}

ul.uk-list-striped li.year-header {background: none; border-bottom: 1px solid $color-primary}

/* Media queries */
@media (min-width: 0px) and (max-width: 319px) {
	.rwd-br {display: block;}
  .logo {text-align: center;}
	#slider {height: 80px}
	.home .slide {width: 100%; height: 240px} 
	.home #slider {height: 240px}
	.feature-links .link-img {height: 73px}
	.feature-links .link-text {padding-top:10px; padding-bottom: 10px }
	.top-tagline {font-size: 18px; line-height: 24px}
	.top-tagline h2 {font-size: 30px; line-height: 40px;padding-left: 40px;}
}

@media (min-width: 320px) and (max-width: 479px) {
	.rwd-br {display: block;}
  .logo {text-align: center;}
	#slider {height: 80px}
	.home .slide {width: 100%; height: 240px} 
	.home #slider {height: 240px}
	.feature-links .link-img {height: 73px}
	.feature-links .link-text {padding-top:10px; padding-bottom: 10px }
	.top-tagline {font-size: 18px; line-height: 24px}
	.top-tagline h2 {font-size: 30px; line-height: 40px;padding-left: 40px;}
}

@media (min-width: 480px) and (max-width: 767px) {
	.rwd-br {display: block;}
  .logo {text-align: center;}
	#slider {height: 120px}
	.home .slide {width: 100%; height: 240px} 
	.home #slider {height: 240px}
	.feature-links .link-text {height: 54px; font-size: 16px}
	.top-tagline {font-size: 18px; line-height: 24px}
	.top-tagline h2 {font-size: 30px; line-height: 40px;padding-left: 40px;}
}

@media (min-width: 768px) and (max-width: 979px) {
.logo img {height: 122px}
	.rwd-br {display: block;}
	#slider {height: 180px}
	.home .slide {width: 100%; height: 360px}
	.home #slider {height: 360px}
	.home-text {padding-right: 25px;}
	.feature-links .link-text {height: 54px; font-size: 18px; line-height: 22px}
	.feature-links .link-img  {height: 73px}
}

@media (min-width: 980px) and (max-width: 1219px) {
.logo img {height: 122px}
	.rwd-br {display: block;}
	#slider {height: $sub-slider-height}
	.home .slide {width: 100%; height: $home-slider-height}
	.home #slider {height: $home-slider-height}
	.home-text {padding-right: 25px;}
	.feature-links .link-text {height: 54px; font-size: 18px; line-height: 22px}
	.feature-links .link-img  {height: 73px}
}

@media (min-width: 1220px) {
.logo img {height: 122px}
	.rwd-br {display: block;}
	#slider {height: $sub-slider-height}
	.home .slide {width: 100%; height: $home-slider-height}
	.home #slider {height: $home-slider-height}
	.home-text {padding-right: 25px;}
	.feature-links .link-text {height: 54px; font-size: 18px; line-height: 22px}
	.feature-links .link-img  {height: 73px}
}
